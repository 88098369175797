import { combineReducers, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CancelAt, CancellationInitiator, V1Subscription } from '@wix/ambassador-subscriptions-api/types';
import { Thunk, ThunkApiConfig } from '../../types/thunk-extra';
import { detailsFixture, subscriptionsFixture, subscriptionsBassFixture } from '../../fixtures';
import { getCancelConfirmModalSubscriptionId, getSubscriptionById } from './selectors';
import { EXPERIMENT_USE_BASS_API, PAID_PLANS_APP_DEF_ID, STORES_APP_DEF_ID } from './constants';
import { Subscription, Action } from '@wix/ambassador-billing-v1-subscription/types';
import { Balance } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Subscription as StoresSubscription } from '@wix/ambassador-wix-ecommerce-subscriptions/types';
import {
  mySubscriptionsCancelSubscriptionConfirmed,
  mySubscriptionsShowDetails,
} from '@wix/bi-logger-subscriptions-bm/v2';
import { Interactions } from '../../types/interactions';
import { isRecurringSubscription } from './domainUtils';
import {
  customerAllowedActions,
  customerCancelSubscription,
  customerQuerySubscriptions,
  customerTurnOffSubscriptionAutoRenewal,
} from '@wix/ambassador-billing-v1-subscription/http';

type LanguageState = string;
const languageSlice = createSlice({
  name: 'language',
  initialState: 'en' as LanguageState,
  reducers: {
    setLanguage: (_, action) => action.payload,
  },
});

type RegionalSettingsState = string;
const regionalSettingsSlice = createSlice({
  name: 'regionalSettings',
  initialState: 'en' as RegionalSettingsState,
  reducers: {
    setRegionalSettings: (_, action) => action.payload,
  },
});

type UserState = any;
const userSlice = createSlice({
  name: 'user',
  initialState: null as UserState,
  reducers: {
    setUser: (_, action) => action.payload,
  },
});

export const { setLanguage } = languageSlice.actions;
export const { setRegionalSettings } = regionalSettingsSlice.actions;
export const { setUser } = userSlice.actions;

// TODO remove all ts expect error after the issue of httpclient types fixed https://wix.slack.com/archives/CBDTV7F5L/p1645966907320119

type AccordionState = any[];
const accordionSlice = createSlice({
  name: 'accordion',
  initialState: [] as AccordionState,
  reducers: {
    open: (state, action) => [...state, action.payload],
    close: (state, action) => state.filter((id) => id !== action.payload),
  },
});

type CancelConfirmModalState = { subscriptionId: null | string; isOpen: boolean };
const cancelConfirmModalSlice = createSlice({
  name: 'cancelConfirmModal',
  initialState: { subscriptionId: null, isOpen: false } as CancelConfirmModalState,
  reducers: {
    open: (state, action) => ({ subscriptionId: action.payload, isOpen: true }),
    close: () => ({ subscriptionId: null, isOpen: false }),
  },
});

export const cancelSubscription = createAsyncThunk<V1Subscription | Subscription, string, ThunkApiConfig>(
  'subscriptions/cancel',
  async (subscriptionId, { extra: { experiments, fedops, subscriptionService, httpClient }, getState, dispatch }) => {
    const state = getState();
    const { user } = state;
    const subscription = getSubscriptionById(state, subscriptionId);

    if (experiments.enabled(EXPERIMENT_USE_BASS_API)) {
      const billingSubscription = subscription as Subscription;
      let response;
      if (isRecurringSubscription(subscription)) {
        // @ts-expect-error
        response = await httpClient.request(customerTurnOffSubscriptionAutoRenewal({ id: billingSubscription?.id }));
      } else {
        // @ts-expect-error
        response = await httpClient.request(customerCancelSubscription({ id: billingSubscription?.id }));
      }
      fedops.interactionEnded(Interactions.SubscriptionCancel);

      // dirty fix for allowed actions
      await dispatch(fetchSubscriptionDetailsById(subscriptionId));

      return response.data.subscription!;
    } else {
      const response = await subscriptionService({
        Authorization: user.instance,
      }).requestCancellation({
        id: subscriptionId,
        cancellationInitiator: CancellationInitiator.MEMBER,
        cancelAt: subscription.recurring ? CancelAt.NEXT_PAYMENT_DATE : CancelAt.IMMEDIATELY,
      });

      fedops.interactionEnded(Interactions.SubscriptionCancel);

      const subscriptionResponse = await subscriptionService({
        Authorization: user.instance,
      }).getSubscription({
        id: response.id,
      });

      return subscriptionResponse.subscription!;
    }
  },
);

export const openCancelConfirmModal = createAsyncThunk<void, string, ThunkApiConfig>(
  'subscriptions/openCancelConfirmModal',
  async (subscriptionId, { extra: { biLogger, experiments }, getState, dispatch }) => {
    const state = getState();
    const subscription = getSubscriptionById(state, subscriptionId);

    if (experiments.enabled(EXPERIMENT_USE_BASS_API)) {
      await biLogger.report(
        mySubscriptionsCancelSubscriptionConfirmed({
          action: 'cancel',
          subscriptionId,
          originEntityId: (subscription as Subscription)?.origin?.entityId || undefined,
          appId: (subscription as Subscription)?.origin?.appId || undefined,
        }),
      );
    } else {
      await biLogger.report(
        mySubscriptionsCancelSubscriptionConfirmed({
          action: 'cancel',
          subscriptionId,
          originEntityId: subscription.originEntityId,
          appId: subscription.wixAppId,
        }),
      );
    }

    dispatch(cancelConfirmModalSlice.actions.open(subscriptionId));
  },
);

export const closeCancelConfirmModal = cancelConfirmModalSlice.actions.close;
export const confirmCancel = createAsyncThunk<void, void, ThunkApiConfig>(
  'cancelConfirmModal/confirmCancel',
  async (arg, { extra: { biLogger, experiments }, dispatch, getState }) => {
    const state = getState();
    const subscriptionId = getCancelConfirmModalSubscriptionId(state) as string;
    const subscription = getSubscriptionById(state, subscriptionId);

    if (experiments.enabled(EXPERIMENT_USE_BASS_API)) {
      await biLogger.report(
        mySubscriptionsCancelSubscriptionConfirmed({
          action: 'cancel-confirm',
          subscriptionId,
          originEntityId: (subscription as Subscription)?.origin?.entityId || undefined,
          appId: (subscription as Subscription)?.origin?.appId || undefined,
        }),
      );
    } else {
      await biLogger.report(
        mySubscriptionsCancelSubscriptionConfirmed({
          action: 'cancel-confirm',
          subscriptionId,
          originEntityId: subscription.originEntityId,
          appId: subscription.wixAppId,
        }),
      );
    }

    await dispatch(cancelSubscription(subscriptionId));
    dispatch(closeCancelConfirmModal());
  },
);

export const fetchAllSubscriptions = createAsyncThunk<
  V1Subscription[] | Subscription[] | undefined,
  void,
  ThunkApiConfig
>('subscriptions/fetchAll', async (_, { extra: { experiments, subscriptionService, httpClient }, getState }) => {
  const { user } = getState();
  if (!user?.loggedIn) {
    return;
  }
  if (experiments.enabled(EXPERIMENT_USE_BASS_API)) {
    const {
      data: { subscriptions },
      // @ts-expect-error
    } = await httpClient.request(customerQuerySubscriptions({}));
    return subscriptions;
  } else {
    const { subscriptions } = await subscriptionService({ Authorization: user.instance }).listSubscriptions({
      subscriberIds: [user.id],
    });
    return subscriptions;
  }
});

type SubscriptionsState = { entities: V1Subscription[] | Subscription[]; loading: string };
export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: { entities: [], loading: 'idle' } as SubscriptionsState,
  reducers: {
    mockSubscriptions: (state, action) => ({
      ...state,
      entities: action.payload,
    }),
  },
  extraReducers: {
    [fetchAllSubscriptions.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    [fetchAllSubscriptions.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.entities.push(...action.payload);
      }
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },
    [fetchAllSubscriptions.rejected.type]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },
    [cancelSubscription.fulfilled.type]: (state, action) => {
      const idx = state.entities.findIndex((s) => s.id === action.payload.id);
      if (idx > -1) {
        state.entities[idx] = action.payload;
      }
    },
  },
});

const fetchSubscriptionDetailsById = createAsyncThunk<any, string, ThunkApiConfig>(
  'subscriptionDetails/fetchById',
  async (
    subscriptionId,
    { extra: { baseUrl, httpClient, ecomSubscriptionsService, memberBenefitsService, experiments }, getState },
  ) => {
    const state = getState();
    const { user } = state;
    const subscription = getSubscriptionById(state, subscriptionId);

    if (!user?.loggedIn) {
      return;
    }

    const headers = { Authorization: user.instance };

    if (experiments.enabled(EXPERIMENT_USE_BASS_API)) {
      const billingSubscription = subscription as Subscription;
      const promiseAllowedActions = httpClient
        // @ts-expect-error
        .request(customerAllowedActions({ id: billingSubscription?.id }))
        .then((response) => response.data.actions)
        .catch(() => []);
      let promiseBalanceItems: Promise<any> = Promise.resolve(undefined);
      let promiseStoresSubscription: Promise<any> = Promise.resolve(undefined);

      if (billingSubscription.origin?.appId === PAID_PLANS_APP_DEF_ID) {
        promiseBalanceItems = memberBenefitsService(headers)
          .getBalance({
            contactId: billingSubscription.customer?.contactId || billingSubscription.customer?.memberId,
            planOrderIds: [billingSubscription.origin?.entityId!],
          })
          .then((response) => response.balanceItems)
          .catch(() => undefined);
      }

      if (billingSubscription.origin?.appId === STORES_APP_DEF_ID) {
        promiseStoresSubscription = ecomSubscriptionsService(headers)
          .getSubscription({
            id: billingSubscription?.origin?.entityId ?? undefined,
          })
          .then((response) => response.subscription)
          .catch(() => undefined);
      }

      const [allowedActions, benefitBalanceItems, storesSubscription] = await Promise.all([
        promiseAllowedActions,
        promiseBalanceItems,
        promiseStoresSubscription,
      ]);
      return {
        allowedActions,
        benefitBalanceItems,
        storesSubscription,
      };
    } else {
      const response = await httpClient.get(
        `${baseUrl}/_serverless/subscriptions-api/subscriptions/${subscriptionId}/details`,
        { headers },
      );
      return response.data;
    }
  },
);

export const openDetails =
  (subscriptionId: string): Thunk =>
  async (dispatch, getState, { biLogger, experiments }) => {
    const subscription = getSubscriptionById(getState(), subscriptionId);
    dispatch(accordionSlice.actions.open(subscriptionId));

    if (!subscriptionId.includes('mock')) {
      if (experiments.enabled(EXPERIMENT_USE_BASS_API)) {
        await biLogger.report(
          mySubscriptionsShowDetails({
            subscriptionId,
            subscriptionStatus: (subscription as Subscription).status,
          }),
        );
      } else {
        await biLogger.report(
          mySubscriptionsShowDetails({
            subscriptionId,
            subscriptionStatus: subscription.subscriptionStatus,
          }),
        );
      }
      await dispatch(fetchSubscriptionDetailsById(subscriptionId));
    }
  };

export const mockSubscriptions =
  (): Thunk =>
  (dispatch, getState, { experiments }) => {
    if (experiments.enabled(EXPERIMENT_USE_BASS_API)) {
      dispatch(subscriptionsSlice.actions.mockSubscriptions(subscriptionsBassFixture));
    } else {
      dispatch(subscriptionsSlice.actions.mockSubscriptions(subscriptionsFixture));
    }
    dispatch(detailsSlice.actions.mockDetails({ ...detailsFixture, id: subscriptionsFixture[0].id }));
    dispatch(openDetails(subscriptionsFixture[0].id!));
  };

export const closeDetails = accordionSlice.actions.close;

type Details = {
  allowedActions?: Action[];
  benefitBalanceItems?: Balance[];
  storesSubscription?: StoresSubscription;
  paymentSubscriptionInfo?: any;
};
type DetailsState = { entities: { [key: string]: Details }; loading: any[] };
const detailsSlice = createSlice({
  name: 'details',
  initialState: { entities: {}, loading: [] } as DetailsState,
  reducers: {
    mockDetails: (state, action) => ({
      ...state,
      entities: { ...state.entities, [action.payload.id]: action.payload },
    }),
  },
  extraReducers: {
    [accordionSlice.actions.open.type]: (state, action) => {
      state.loading.push(action.payload);
    },
    [fetchSubscriptionDetailsById.fulfilled.type]: (state, action) => {
      const subscriptionId = action.meta.arg;
      state.entities[subscriptionId] = action.payload;
      state.loading = state.loading.filter((id) => id !== subscriptionId);
    },
    [fetchSubscriptionDetailsById.rejected.type]: (state, action) => {
      state.loading = state.loading.filter((id) => id !== action.meta.arg);
    },
  },
});

export interface RootState {
  cancelConfirmModal: CancelConfirmModalState;
  language: LanguageState;
  regionalSettings: RegionalSettingsState;
  accordion: AccordionState;
  subscriptions: SubscriptionsState;
  details: DetailsState;
  user: UserState;
}

const rootReducer = combineReducers({
  cancelConfirmModal: cancelConfirmModalSlice.reducer,
  language: languageSlice.reducer,
  regionalSettings: regionalSettingsSlice.reducer,
  accordion: accordionSlice.reducer,
  subscriptions: subscriptionsSlice.reducer,
  details: detailsSlice.reducer,
  user: userSlice.reducer,
});

export default rootReducer;
